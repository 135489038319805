//static data for all components
const navLinks = [
  {
    name: 'CARE',
    url: '/',
  },
  {
    name: 'FITNESS',
    url: '/fitness',
  },
];

const countryData = [
  { name: 'India' },
  { name: 'UAE' },
  { name: 'Turkey' },
  { name: 'Thailand' },
  { name: 'India' },
  { name: 'UAE' },
  { name: 'Turkey' },
  { name: 'Thailand' },
];

const steps = [
  {
    id: 1,
    name: 'Explore & Match',
    bgColor: '#44469F',
  },
  {
    id: 2,
    name: 'Doctor Opinion & Diagnosis',
    bgColor: '#0065A9',
  },
  {
    id: 3,
    name: 'Cost Estimate',
    bgColor: '#00BDDA',
  },
  {
    id: 4,
    name: 'Medical Visa',
    bgColor: '#009BBD',
  },
  {
    id: 5,
    name: 'On Field Assistance',
    bgColor: '#028272',
  },
  {
    id: 6,
    name: 'Treat & Get Fit',
    bgColor: '#007A46',
  },
];

const fitnessBannerDiseases = [
  {
    id: 1,
    name: 'Diabetes',
    icon: '/images/diabetes.svg',
  },
  {
    id: 2,
    name: 'Hypertension',
    icon: '/images/hypertension.svg',
  },
  {
    id: 3,
    name: 'Thyroid',
    icon: '/images/thyroid.svg',
  },
  {
    id: 4,
    name: 'Obesity',
    icon: '/images/obesity.svg',
  },
  {
    id: 5,
    name: 'PCOD/PCOS',
    icon: '/images/pcod.svg',
  },
  {
    id: 6,
    name: 'Pain Relief',
    icon: '/images/painRelief.svg',
  },
];

const painReliefInfo = [
  {
    id: 1,
    title: 'Prevention',
    description:
            'Build strength and flexibility to stay healthy and avoid any future health issues',
    img: '/images/painReliefPrevention.png',
    color: '#0091BE',
  },
  {
    id: 2,
    title: 'Chronic Pain',
    description:
            'Get freedom from chronic pain in a natural way to live a healthy and happy life',
    img: '/images/painReliefChronicPain.png',
    color: '#00A7CB',
  },
  {
    id: 3,
    title: 'Pre/post surgery & health conditions',
    description: 'Recover in a natural way to live a medicine-free life',
    img: '/images/painReliefSurgery.png',
    color: '#00C3DD',
  },
];

const services = [
  // {
  //   id: 1,
  //   imgSrc: "/images/talkToDoctors.webp",
  //   name: "Talk To Doctor",
  //   heading: "Get Consultation with the best doctors",
  //   subHeading: "",
  //   linkName: "Consult Now",
  //   linkUrl: "",
  // },
  // {
  //   id: 2,
  //   imgSrc: "/images/yourSymptoms.webp",
  //   name: "Your Symptoms",
  //   heading: "Provide accurate symptoms to facilitate better diagnosis",
  //   subHeading: "",
  //   linkName: "Go",
  //   linkUrl: "",
  // },
  {
    id: 3,

    imgSrc: '/images/aestheticTreatments.webp',

    name: 'AESTHETIC TREATMENTS',
    heading: 'Best Treatment at affordable prices',
    subHeading:
            'Treatments for Hair Transplants, Dental, Tummy tuck, root canal, Rhino plasty and more',
    linkName: 'Explore Cosmetic',
    linkUrl: '',
  },
  {
    id: 4,
    imgSrc: '/images/wellnessTreatments.webp',
    name: 'WELLNESS TREATMENTS',
    heading: 'Give yourself the time to relax',
    subHeading:
            'Treatments for Weight loss, Detox, Destress, Traditional Treatments, 3 day health rips and more',
    linkName: 'Explore Wellness',
    linkUrl: '',
  },
];

const fitnessStepsInfo = [
  {
    id: '01',
    title: '1-on-1 sessions with an expert',
    description:
            'Scanning results from the technology are analyzed deeply  the expert to further understand the body better',
    img: '/images/fitnessSteps1.webp',
  },
  {
    id: '02',
    title: 'A detailed assessment report',
    description:
            'Data from technology and medical expert are combined with Vedic science to build a customized plan for recovery',
    img: '/images/fitnessSteps2.webp',
  },
  {
    id: '03',
    title: 'Starting the program and constant progress monitoring',
    description:
            'start sessions in expert care and constant monitoring of progress to help you reach a pain-free life forever',
    img: '/images/fitnessSteps3.webp',
  },
];

const personalizedCoachingInfo = [
  {
    id: 1,
    title: 'Understanding',
    desc: 'Book a consultation with our experts for us to understand how Fitsib can hekp you bauild the perfect raodmap to yourr health goals',
  },
  {
    id: 2,
    title: 'Customize',
    desc: 'Receive a carefully designed plan that is personalized to your needs and assimilates easily into your daily life',
  },
  {
    id: 3,
    title: 'Daily Tracking',
    desc: 'Check-in with your personal coach to monitor your progress and address any questions or concerns',
  },
  {
    id: 4,
    title: 'Getting Result',
    desc: 'Achieve holistic wellbeing that is longlasting with small but powerful changes in your nutrition, eating habits and lifestyle',
  },
];

const experts = [
  {
    id: 1,
    name: 'Mayukha Thakur',
    img: '/images/expert1.webp',
    designation: 'Assistant Manager',
  },
  {
    id: 2,
    name: 'Shraddha Abrol',
    img: '/images/expert2.webp',
    designation: 'Assistant Manager',
  },
  {
    id: 3,
    name: 'Aastha Behl',
    img: '/images/expert3.webp',
    designation: 'Dietitian',
  },
  {
    id: 4,
    name: 'Aastha Behl',
    img: '/images/expert4.webp',
    designation: 'Dietitian',
  },
];

const impact = [
  {
    id: 1,
    count: '1.5',
    number: 'Lakh',
    name: 'Surgeries',
    icon: '/images/surgeriesImpact.svg',
    key: 'Surgeries',
  },
  {
    id: 2,
    count: '45',
    number: '+',
    name: 'Cities',
    icon: '/images/citiesImpact.svg',
    key: 'Cities',
  },
  {
    id: 3,
    count: '2',
    number: 'M+',
    name: 'Happy Patients',
    icon: '/images/patientsImpact.svg',
    key: 'Happy_Patients',
  },
  {
    id: 4,
    count: '670',
    number: '+',
    name: 'Doctors',
    icon: '/images/doctorsImpact.svg',
    key: 'Doctors',
  },
];

const footerInfo = [
  {
    //   id: 1,
    //   title: "Top Treatments",
    //   values: [
    //     { name: "Cardiology", link: "" },
    //     { name: "ENT", link: "" },
    //     { name: "General", link: "" },
    //     { name: "Neurology", link: "" },
    //     { name: "Oncology", link: "" },
    //   ],
    // },
    // {
    //   id: 2,
    //   title: "Hospitals",
    //   values: [
    //     { name: "Fortis Hospital,Noida", link: "" },
    //     { name: "Fortis Escorts Heart Institute", link: "" },
    //     { name: "Fortis Vasant Kunj", link: "" },
    //     { name: "Fortis Memorial Research Institute, Gurgaon", link: "" },
    //     { name: "Fortis Shalimar Bagh", link: "" },
    //   ],
    // },
    // {
    //   id: 3,
    //   title: "Doctors",
    //   values: [
    //     { name: "Dr. Pradeep Chowbey", link: "" },
    //     { name: "Dr Suraj Munjal", link: "" },
    //     { name: "Dr Naresh Trehan", link: "" },
    //     { name: "Dr Ashok Rajgopal", link: "" },
    //   ],
    // },
    // {
    //   id: 4,
    //   title: "Explore",
    //   values: [
    //     { name: "Curestay", link: "" },
    //     { name: "Hospals Assured", link: "" },
    //     { name: "Healthtrip Blogs", link: "" },
    //     { name: "Online Consultation", link: "" },
    //     { name: "Become an Affiliate", link: "" },
    //   ],
    // },
    // {
    id: 5,
    title: 'About',
    values: [
      { name: 'About Us', link: '/about' },
      { name: 'Contact Us', link: '/contact-us' },
      { name: 'Terms Of Use', link: '/terms-conditions' },
      { name: 'Privacy Policy', link: '/privacy-policy' },
    ],
  },
];

const treatments = [
  { imgSrc: '/images/oncology.svg', name: 'Oncology', id: 0 },
  { imgSrc: '/images/urology.svg', name: 'Nephrology & Urology', id: 1 },
  // { imgSrc: "/images/bariatric.svg", name: "Gastroentrology", id: 2 },
  // { imgSrc: "/images/liver.svg", name: "Hepatology", id: 3 },
  // { imgSrc: "/images/ortho.svg", name: "Orthopedic", id: 4 },
  { imgSrc: '/images/cardiology.svg', name: 'Cardiology', id: 5 },
  // { imgSrc: "/images/pulmonology.svg", name: "Pulmonology", id: 6 },
  // { imgSrc: "/images/neurology.svg", name: "Neurology", id: 7 },
  // { imgSrc: "/images/general.svg", name: "General", id: 8 },
  // { imgSrc: "/images/dermatology.svg", name: "Dermatology ", id: 9 },
  // { imgSrc: "/images/diabetes.svg", name: "Diabetes ", id: 10 },
  // { imgSrc: "/images/ent.svg", name: "Ent", id: 11 },
  // { imgSrc: "/images/endocrinology.svg", name: "Endocrinology", id: 12 },
  { imgSrc: '/images/transplant.svg', name: 'Transplant', id: 13 },
  // { imgSrc: "/images/vascular.svg", name: "Vascular Surgery", id: 14 },
  // { imgSrc: "/images/hematology.svg", name: "Hematology", id: 15 },
  // { imgSrc: "/images/ophthalmology.svg", name: "Ophthalmology", id: 16 },
  // { imgSrc: "/images/dentistry.svg", name: "Dentistry", id: 17 },
  // { imgSrc: "/images/gynecology.svg", name: "Obstetrics", id: 18 },
  // { imgSrc: "/images/gynecologist.svg", name: "Gynecologist", id: 19 },
  { imgSrc: '/images/bariatric.svg', name: 'GI & Bariatric', id: 20 },
  // { imgSrc: "/images/pediatrician.svg", name: "Pediatrician", id: 21 },

  { imgSrc: '/images/neuro.svg', name: 'Neuro / Spine' },
  // { imgSrc: "/images/pediatrician.svg", name: "Pediatrician" },
  { imgSrc: '/images/ortho.svg', name: 'Orthopedic' },
  // { imgSrc: "/images/ent.svg", name: "Ent" },
  // { imgSrc: "/images/liver.svg", name: "Liver Transplant" },
  // { imgSrc: "/images/neurology.svg", name: "Neurology" },
  // { imgSrc: "/images/dermatology.svg", name: "Dermatology " },
  // { imgSrc: "/images/liver.svg", name: "Liver Transplant" },
];

const treatmentPackages = [
  {
    packageName: 'Destress Package1',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package2',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package3',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package4',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package5',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package6',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package7',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package8',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
  {
    packageName: 'Destress Package',
    flightDuration: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospitalName: 'Agni Ayurvedic Village',
    packageCost: '$500',
    imgSrc: '/images/package.webp',
  },
];

const expertsList = [
  {
    id: 1,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 2,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 3,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 4,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 5,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 6,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 7,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 8,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 9,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 10,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
  {
    id: 11,
    name: 'Maharishi Mahesh Yogi',
    rating: 4,
    img: '/images/avatar.svg',
    level: 'Level-3',
    designation: 'Yoga Teacher',
    experience: '20+years',
    classes: '4500+',
  },
];
const testimonials = [
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
  {
    imgSrc: '/images/avatar.svg',
    username: 'Hazrat Muhammed Abdul Qashem Noori',
    treatment: 'Hair Restoration',
    country: 'Bangladesh',
    testimonial:
            "I can't thank Healthtrip.com enough for the life-changing experience they have provided me.",
  },
];

const hospitals = [
  {
    imgSrc: '/images/hospital 5.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
  {
    imgSrc: '/images/hospital2.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
  {
    imgSrc: '/images/hospital3.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
  {
    imgSrc: '/images/hospital1.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
  {
    imgSrc: '/images/hospital2.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
  {
    imgSrc: '/images/hospital3.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
  {
    imgSrc: '/images/hospital1.webp',
    name: 'Fortis Hospital',
    place: 'Noida',
    address:
            ' E-14, 3rd Floor, Chauhan Vithi Rd, Block E, Defence Colony, New Delhi, Delhi 110024',
  },
];

const doctors = [
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
  {
    imgSrc: '/images/doctorAvatar.webp',
    name: 'Dr. Pooja Mishra',
    position: 'Chairman',
    studiedAt: 'Max Institute Of Laparoscopic, Endoscopic',
    consultAt: 'Max Healthcare Saket',
    experience: '45+ Years',
    surgeries: '85000+',
  },
];

const successStories = [
  {
    imgSrc: '/images/story1.webp',
    storyBy: ' Zafeer Ahmad',
    date: 'Aug 12, 2023',
    tags: [
      'Burjeel Medi',
      'Critical Care',
      'Burjeel Medi',
      'Burjeel Medi',
      'Burjeel Medi',
    ],
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. More...',
  },
  {
    imgSrc: '/images/story2.webp',
    storyBy: ' Zafeer Ahmad',
    date: 'Aug 12, 2023',
    tags: [
      'Burjeel Medi',
      'Critical Care',
      'Burjeel Medi',
      'Burjeel Medi',
      'Burjeel Medi',
    ],
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. More...',
  },
  {
    imgSrc: '/images/story3.webp',
    storyBy: ' Zafeer Ahmad',
    date: 'Aug 12, 2023',
    tags: [
      'Burjeel Medi',
      'Critical Care',
      'Burjeel Medi',
      'Burjeel Medi',
      'Burjeel Medi',
    ],
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. More...',
  },
  {
    imgSrc: '/images/story1.webp',
    storyBy: ' Zafeer Ahmad',
    date: 'Aug 12, 2023',
    tags: [
      'Burjeel Medi',
      'Critical Care',
      'Burjeel Medi',
      'Burjeel Medi',
      'Burjeel Medi',
    ],
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. More...',
  },
  {
    imgSrc: '/images/story2.webp',
    storyBy: ' Zafeer Ahmad',
    date: 'Aug 12, 2023',
    tags: [
      'Burjeel Medi',
      'Critical Care',
      'Burjeel Medi',
      'Burjeel Medi',
      'Burjeel Medi',
    ],
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. More...',
  },
  {
    imgSrc: '/images/story3.webp',
    storyBy: ' Zafeer Ahmad',
    date: 'Aug 12, 2023',
    tags: [
      'Burjeel Medi',
      'Critical Care',
      'Burjeel Medi',
      'Burjeel Medi',
      'Burjeel Medi',
    ],
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. More...',
  },
];

const blogs = [
  {
    imgSrc: '/images/blog3.webp',
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. ',
  },
  {
    imgSrc: '/images/blog4.webp',
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. ',
  },
  {
    imgSrc: '/images/blog3.webp',
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. ',
  },
  {
    imgSrc: '/images/blog4.webp',
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. ',
  },
  {
    imgSrc: '/images/blog3.webp',
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. ',
  },
  {
    imgSrc: '/images/blog4.webp',
    heading:
            "Burjeel Hospital's Critical Care and ICU: Providing the Best Possible Care for Critically Ill Patients",
    subHeading:
            'In the realm of healthcare, the provision of exceptional critical care can make all the difference between life and death for critically ill patients. ',
  },
];

const reviews = [
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
  {
    imgSrc: '/images/avatar2.svg',
    name: 'Dr. Pradeep Chowbey ',
    date: '12 September 2023',
    review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet commodo ipsum, id convallis eros congue vitae. Ut mollis in arcu quis sodales.',
  },
];

const videosInfo = [
  {
    imgSrc: '/images/video1.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video2.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video3.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video4.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video5.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video1.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video2.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
  {
    imgSrc: '/images/video3.webp',
    heading:
            'Difference between Coronary Artery Disease (CAD) and Congestive Heart Failure (CHF)',
  },
];

const packagesFilter = [
  {
    id: 1,
    title: 'All Departments',
    values: [
      {
        id: '1',
        name: 'department 1',
      },
      {
        id: '2',
        name: 'department 2',
      },
    ],
  },
  {
    id: 2,
    title: 'All Cities',
    values: [
      {
        id: '1',
        name: 'Delhi NCR',
      },
      {
        id: '2',
        name: 'Chandigarh',
      },
      {
        id: '3',
        name: 'Bangalore',
      },
    ],
  },

  {
    id: 3,
    title: 'All Hospitals',
    values: [
      {
        id: '1',
        name: 'Fortis',
      },
      {
        id: '2',
        name: 'Max Healthcare',
      },
      {
        id: '3',
        name: 'Medanta',
      },
    ],
  },
  {
    id: 4,
    title: 'Price',
    values: [
      {
        id: '1000-2000',
        name: '1000-2000',
      },
      {
        id: '2000-3000',
        name: '2000-3000',
      },
      {
        id: '3000-5000',
        name: '3000-5000',
      },
    ],
  },
];

const packages = [
  {
    id: 1,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    treatmentCost: [
      {
        id: 1,
        info: 'Total Hip Replacement is also called Total Hip Arthroplasty in which the damaged bone and cartilage are removed and replaced with prosthetic components.',
      },
      {
        id: 2,
        info: 'The procedure involves the removal of the degenerated femoral head and socket and replaces them with artificial components.',
      },
    ],
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar',
      'High BP, sugar',
    ],
    treatment: [
      'alve replacement takes around four to five hours.',
      'It starts with giving anesthesia to the patient so that the patient does not feel any pain and becomes unconscious.',
      'Then doctors make a cut on the patient’s chest to access the valve to proceed further.',
      'In order to protect the heart, the doctor gives the medicine.',
    ],
    inclusions: [
      {
        id: 1,
        name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
      },
      {
        id: 2,
        name: 'Surgery cost (varies depending on the type of implant)',
      },
      {
        id: 3,
        name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
      },
    ],

    factors: [
      {
        id: 1,
        info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
      },
      {
        id: 2,
        info: 'The severity of the disease',
      },
      {
        id: 3,
        info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
      },
      {
        id: 4,
        info: 'Cost of Blood products (if required)',
      },
      {
        id: 5,
        info: 'An extended stay at the Hospital',
      },
    ],
    costInfoAcrossIndia: [
      {
        id: 1,
        name: 'Total Hip Replacement',
        cost: '$5000',
      },
      {
        id: 2,
        name: 'Revision Hip Replacement',
        cost: '$6000',
      },
      {
        id: 3,
        name: 'Cemented Total Hip Replacement',
        cost: '$5000',
      },
      {
        id: 4,
        name: 'Gastrointestinal Cancer',
        cost: '$5000',
      },
      {
        id: 5,
        name: 'Cementless Total Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
      {
        id: 6,
        name: 'Cementless Total Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
      {
        id: 7,
        name: 'Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
      {
        id: 8,
        name: 'Cementless Total Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
    ],
    cityWiseCost: [
      {
        id: 1,
        city: 'New Delhi',
        cost: 'Rs.243867 to Rs.381433',
      },
      {
        id: 2,
        city: 'Gurgaon',
        cost: 'Rs.243867 to Rs.381433',
      },
      {
        id: 3,
        city: 'Noida',
        cost: 'Rs.40000 to Rs.38000',
      },
      {
        id: 4,
        city: 'Kolkata',
        cost: 'Rs.24000 to Rs.330099',
      },
      {
        id: 5,
        city: 'Hyderabad',
        cost: 'Rs.24000 to Rs.330099',
      },
      {
        id: 6,
        city: 'Bangalore',
        cost: 'Rs.24000 to Rs.330099',
      },
      {
        id: 7,
        city: 'Mumbai',
        cost: 'Rs.24000 to Rs.330099',
      },
    ],
    diagnosis: [
      {
        id: 1,
        name: 'Electrocardiogram (ECG)',
        info: 'ECG measures the rate and regularity of the heartbeat.',
      },
      {
        id: 2,
        name: 'Echocardiogram',
        info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
      },
      {
        id: 3,
        name: 'Stress test',
        info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
      },
      {
        id: 4,
        name: ' Medical History and Physical Examination',
        info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
      },
      {
        id: 5,
        name: 'Imaging Studies',
        info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
      },
    ],
  },
  {
    id: 2,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar, and cholesterol',
    ],
  },
  {
    id: 3,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar, and cholesterol',
    ],
  },
  {
    id: 4,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar, and cholesterol',
    ],
  },
  {
    id: 5,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar, and cholesterol',
    ],
  },
  {
    id: 6,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar, and cholesterol',
    ],
  },
  {
    id: 7,
    to: 'Oman',
    from: 'India',
    name: 'Total Hip Replacement',
    place: 'New Delhi,India',
    flight: '1 to 3 hours flight',
    duration: '3 nights 4 days',
    hospital: 'Fortis Hospital',
    price: '$5000',
    img: '/images/packages.svg',
    symptoms: [
      'Severe tiredness',
      'Swelling in the hands and feet',
      'Nausea and weakness',
      'Shortness of breath',
      'Pain in arms or shoulders',
      'Indigestion',
    ],
    causes: [
      'Osteoarthritis',
      'Rheumatoid Arthritis',
      'Hip Fractures',
      'Avascular Necrosis',
      'Heart defect since birth',
      'High BP, sugar, and cholesterol',
    ],
  },
];

const expertFilter = [
  {
    id: 1,
    title: 'Price Range',
    values: [
      {
        id: '1',
        name: '1000-2000',
      },
      {
        id: '2',
        name: '2000-3000',
      },
    ],
  },
  {
    id: 2,
    title: 'Cities',
    values: [
      {
        id: '1',
        name: 'Delhi NCR',
      },
      {
        id: '2',
        name: 'Chandigarh',
      },
      {
        id: '3',
        name: 'Bangalore',
      },
    ],
  },
  {
    id: 3,
    title: 'Experiance',
    values: [
      {
        id: 1,
        name: '1+ year',
      },
      {
        id: 3,
        name: '3+ year',
      },
      {
        id: 5,
        name: '5+ year',
      },
    ],
  },
  {
    id: 4,
    title: 'Surgeries',
    values: [
      {
        id: 200,
        name: '200 +',
      },
      {
        id: 300,
        name: '300 +',
      },
      {
        id: 400,
        name: '400 +',
      },
    ],
  },
  {
    id: 6,
    title: 'Gender',
    values: [
      {
        id: 'Male',
        name: 'Male',
      },
      {
        id: 'Female',
        name: 'Female',
      },
      {
        id: '3',
        name: 'Trans gender',
      },
    ],
  },
];

const subTreatments = {
  oncology: {
    subTreatments: [
      {
        id: 1,
        name: 'Valve Replacement',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],

        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 2,
        name: 'Heart Transplant',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 7,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 3,
        name: 'Cervical Cancer',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 4,
        name: 'Colon Cancer',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 5,
        name: 'Chemotherapy',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 6,
        name: 'Colon Cancer',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 7,
        name: 'Kidney Cancer',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 8,
        name: 'Fallopian Tube Cancer',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
      {
        id: 9,
        name: 'Heart Transplant',
        treatmentCost: [
          {
            id: 1,
            info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
          },
          {
            id: 2,
            info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
          },
          {
            id: 3,
            info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
          },
        ],
        inclusions: [
          {
            id: 1,
            name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
          },
          {
            id: 2,
            name: 'Surgery cost (varies depending on the type of implant)',
          },
          {
            id: 3,
            name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
          },
          {
            id: 4,
            name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
          },
          {
            id: 5,
            name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
          },
        ],
        factors: [
          {
            id: 1,
            info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
          },
          {
            id: 2,
            info: 'The severity of the disease',
          },
          {
            id: 3,
            info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
          },
          {
            id: 4,
            info: 'Cost of Blood products (if required)',
          },
          {
            id: 5,
            info: 'An extended stay at the Hospital',
          },
        ],
        costInfoAcrossIndia: [
          {
            id: 1,
            name: 'Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 2,
            name: 'Revision Hip Replacement',
            cost: '$6000',
          },
          {
            id: 3,
            name: 'Cemented Total Hip Replacement',
            cost: '$5000',
          },
          {
            id: 4,
            name: 'Gastrointestinal Cancer',
            cost: '$5000',
          },
          {
            id: 5,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
          {
            id: 6,
            name: 'Cementless Total Hip Replacement (THR) Surgery',
            cost: '$4000',
          },
        ],
        cityWiseCost: [
          {
            id: 1,
            city: 'New Delhi',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 2,
            city: 'Gurgaon',
            cost: 'Rs.243867 to Rs.381433',
          },
          {
            id: 3,
            city: 'Noida',
            cost: 'Rs.40000 to Rs.38000',
          },
          {
            id: 4,
            city: 'Kolkata',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 5,
            city: 'Hyderabad',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 6,
            city: 'Bangalore',
            cost: 'Rs.24000 to Rs.330099',
          },
          {
            id: 7,
            city: 'Mumbai',
            cost: 'Rs.24000 to Rs.330099',
          },
        ],
        diagnosis: [
          {
            id: 1,
            name: 'Electrocardiogram (ECG)',
            info: 'ECG measures the rate and regularity of the heartbeat.',
          },
          {
            id: 2,
            name: 'Echocardiogram',
            info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
          },
          {
            id: 3,
            name: 'Stress test',
            info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
          },
          {
            id: 4,
            name: ' Medical History and Physical Examination',
            info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
          },
          {
            id: 5,
            name: 'Imaging Studies',
            info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
          },
        ],
      },
    ],
  },
};

const specialitiesObj = {
  0: {
    treatmentName: 'Oncology',
    specialities: [
      { id: 0, name: 'Valve Replacement' },
      { id: 1, name: 'Cervical Cancer' },
      { id: 2, name: 'Chemotherapy' },
      { id: 3, name: 'Colon Cancer' },
      { id: 0, name: 'Valve Replacement' },
      { id: 1, name: 'Cervical Cancer' },
      { id: 2, name: 'Chemotherapy' },
      { id: 3, name: 'Colon Cancer' },
    ],
  },
};

const specialitiesInfoObj = {
  0: {
    name: 'Valve Replacement',
    about: `<p>
    The heart has four valves to keep the blood flow in the right direction.
    These are the mitral valve, tricuspid valve, pulmonary valve, and aortic
    valve. Each valve closes and opens during every heartbeat.
    <br />
    The process of replacing the natural diseased and damaged heart valve
    with a prosthetic valve to mimic the exact functioning of the valve is
    known as heart valve replacement. A prosthetic valve is designed to aid
    normal opening and closing motion, exactly like the natural valve. The
    cause of damage can be a result of calcium build up in the valve due to
    aging or other factors. Mostly, open-heart surgery is the option for
    heart valve replacement.
    <br />
    The process of replacing the natural diseased and damaged heart valve
    with a prosthetic valve to mimic the exact functioning of the valve is
    known as heart valve replacement. A prosthetic valve is designed to aid
    normal opening and closing motion, exactly like the natural valve. The
    cause of damage can be a result of calcium build up in the valve due to
    aging or other factors. Mostly, open-heart surgery is the option for
    heart valve replacement.
  </p>`,
    treatmentCost: [
      {
        id: 1,
        info: 'Heart valve replacement cost in India is around USD 6000, which varies depending upon various factors.',
      },
      {
        id: 2,
        info: 'There is a 90% success rate of heart valve replacement treatment in India, which makes India one of the most preferred countries for it in the world.',
      },
      {
        id: 3,
        info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
      },
      {
        id: 4,
        info: 'It is a six to eight days procedure in the hospital, and patients need to stay for almost ten days in India.',
      },
    ],
    inclusions: [
      {
        id: 1,
        name: 'Preoperative diagnostic tests cost (such as blood tests or X-rays)',
      },
      {
        id: 2,
        name: 'Surgery cost (varies depending on the type of implant)',
      },
      {
        id: 3,
        name: 'Types of Implant (Ceramic, Metal-on-Metal, Cemented Hybrid, Cementless)',
      },
      {
        id: 4,
        name: 'Type of Valve (2 variables are available - Mechanical valve and Tissue Valve) Post-Operative cost (depends on the number of follow-up sessions)',
      },
      {
        id: 5,
        name: 'Preoperative diagnostic tests cost [blood tests, a chest X-ray, an echocardiogram (ECG) and an electrocardiogram (EKG)].',
      },
    ],
    factors: [
      {
        id: 1,
        info: 'Type of hospital and room opted (General, Twin sharing, or single room)',
      },
      {
        id: 2,
        info: 'The severity of the disease',
      },
      {
        id: 3,
        info: 'Post-surgical complication, if it happens (such as infection, blood clot formation, etc.)',
      },
      {
        id: 4,
        info: 'Cost of Blood products (if required)',
      },
      {
        id: 5,
        info: 'An extended stay at the Hospital',
      },
    ],
    costInfoAcrossIndia: [
      {
        id: 1,
        name: 'Total Hip Replacement',
        cost: '$5000',
      },
      {
        id: 2,
        name: 'Revision Hip Replacement',
        cost: '$6000',
      },
      {
        id: 3,
        name: 'Cemented Total Hip Replacement',
        cost: '$5000',
      },
      {
        id: 4,
        name: 'Gastrointestinal Cancer',
        cost: '$5000',
      },
      {
        id: 5,
        name: 'Cementless Total Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
      {
        id: 6,
        name: 'Cementless Total Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
      {
        id: 7,
        name: 'Cementless Total Hip Replacement (THR) Surgery',
        cost: '$4000',
      },
    ],
    cityWiseCost: [
      {
        id: 1,
        city: 'New Delhi',
        cost: 'Rs.243867 to Rs.381433',
      },
      {
        id: 2,
        city: 'Gurgaon',
        cost: 'Rs.243867 to Rs.381433',
      },
      {
        id: 3,
        city: 'Noida',
        cost: 'Rs.40000 to Rs.38000',
      },
      {
        id: 4,
        city: 'Kolkata',
        cost: 'Rs.24000 to Rs.330099',
      },
      {
        id: 5,
        city: 'Hyderabad',
        cost: 'Rs.24000 to Rs.330099',
      },
      {
        id: 6,
        city: 'Bangalore',
        cost: 'Rs.24000 to Rs.330099',
      },
      {
        id: 7,
        city: 'Mumbai',
        cost: 'Rs.24000 to Rs.330099',
      },
    ],
    diagnosis: [
      {
        id: 1,
        name: 'Electrocardiogram (ECG)',
        info: 'ECG measures the rate and regularity of the heartbeat.',
      },
      {
        id: 2,
        name: 'Echocardiogram',
        info: 'This test uses special sound waves to create a picture of the heart to know how well the heart is working and the condition of the valves.',
      },
      {
        id: 3,
        name: 'Stress test',
        info: 'This test tells the heart’s condition while doing any physical exercise like walking on the treadmill.',
      },
      {
        id: 4,
        name: ' Medical History and Physical Examination',
        info: "The orthopaedic surgeon will review the patients medical history and conduct a physical examination to assess the hip joint's range of motion, strength, and stability. They will also inquire about the patient's symptoms, such as pain, stiffness, and difficulty walking.",
      },
      {
        id: 5,
        name: 'Imaging Studies',
        info: '   X-rays, magnetic resonance imaging (MRI), or computed tomography (CT) scans are used to obtain detailed images of the hip joint.These imaging studies help identify the extent of joint damage, the condition of surrounding tissues, and the suitability of the patient for total hip replacement.',
      },
    ],
  },
};

const faqData = {
  rows: [
    {
      title: 'How much do the tests for valve replacement cost?',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
              ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
              In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
              Fusce sed commodo purus, at tempus turpis.`,
    },
    {
      title: 'What are the various types of valves and how much do they cost?',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                Fusce sed commodo purus, at tempus turpis.`,
    },
    {
      title: 'Does the package include the cost of medicines?',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                Fusce sed commodo purus, at tempus turpis.`,
    },
    {
      title: 'How long does the patient need to stay in the hospital?',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                Fusce sed commodo purus, at tempus turpis.`,
    },
    {
      title: 'Are there any post-treatment expenses after valve replacement?',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                Fusce sed commodo purus, at tempus turpis.`,
    },
  ],
};

const Testimonials1 = [
  {
    testimonial:
            'I suffered from acute leukemia in 2019, and now I am completely cured of it. I owe this second life to Hospals. It was only because of their affordably priced treatment packages in India that I could afford the treatment. They should keep up the excellent work and will definitely make a significant difference to many people’s lives.',
    user: 'Alexa Windsor, UAE',
  },
  {
    testimonial:
            'I suffered from acute leukemia in 2019, and now I am completely cured of it. I owe this second life to Hospals. It was only because of their affordably priced treatment packages in India that I could afford the treatment. They should keep up the excellent work and will definitely make a significant difference to many people’s lives.',
    user: 'Alexa Windsor, UAE',
  },
  {
    testimonial:
            'I suffered from acute leukemia in 2019, and now I am completely cured of it. I owe this second life to Hospals. It was only because of their affordably priced treatment packages in India that I could afford the treatment. They should keep up the excellent work and will definitely make a significant difference to many people’s lives.',
    user: 'Alexa Windsor, UAE',
  },
];

const wellnessTreatmentCarousel = [
  { name: 'Overall Wellness', img: '/images/overallWellness.webp' },
  { name: 'Face Wellness', img: '/images/faceWellness.webp' },
  { name: 'Pain and Recovery', img: '/images/painAndRecovery.webp' },
  { name: 'Targeted Slimming', img: '/images/targetedSlimming.webp' },
];

const blogFilter = [
  {
    id: 1,
    title: 'All Departments',
    values: [
      {
        id: '1',
        name: 'Oncology',
      },
      {
        id: '2',
        name: 'Ortho',
      },
      {
        id: '3',
        name: 'Cancer',
      },
    ],
  },
  {
    id: 2,
    title: 'All Treatments',
    values: [
      {
        id: '1',
        name: 'Cervical Cancer',
      },
      {
        id: '2',
        name: 'Hip Replacemnet',
      },
      {
        id: '3',
        name: 'Valve Replacement',
      },
    ],
  },
  {
    id: 3,
    title: 'All Hospitals',
    values: [
      {
        id: '1',
        name: 'Max Healthcare',
      },
      {
        id: '2',
        name: 'Fortis',
      },
      {
        id: '3',
        name: 'Medanta',
      },
    ],
  },
  {
    id: 4,
    title: 'All Doctors',
    values: [
      {
        id: '1',
        name: 'Dr.Pooja Sharma',
      },
      {
        id: '2',
        name: 'Dr Pooja Sharma',
      },
    ],
  },
];

const customerReviews = [
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of Oncology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Oncology',
    place: 'Bangladesh',
    customerName: 'Bishal Chakraborty',
  },
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of neurology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Neurology',
    place: 'South Africa',
    customerName: 'Bishal Chakraborty',
  },
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of oncology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Oncology',
    place: 'Mumbai',
    customerName: 'Bishal Chakraborty',
  },
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of neurology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Neurology',
    place: 'Sri Lanka',
    customerName: 'Bishal Chakraborty',
  },
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of neurology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Neurology',
    place: 'Sri Lanka',
    customerName: 'Bishal Chakraborty',
  },
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of neurology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Neurology',
    place: 'Sri Lanka',
    customerName: 'Bishal Chakraborty',
  },
  {
    imgSrc: '/images/reviewAvatar.svg',
    review: 'fitsib.com helped me find the top doctors in the field of neurology, and their teleconsultation feature allowed me to have quick and convenient access to expert medical advice. I am grateful for their services',
    department: 'Neurology',
    place: 'Sri Lanka',
    customerName: 'Bishal Chakraborty',
  },
];

export {
  steps,
  fitnessBannerDiseases,
  painReliefInfo,
  fitnessStepsInfo,
  personalizedCoachingInfo,
  experts,
  impact,
  footerInfo,
  treatments,
  treatmentPackages,
  services,
  navLinks,
  countryData,
  expertFilter,
  expertsList,
  testimonials,
  hospitals,
  doctors,
  successStories,
  blogs,
  reviews,
  videosInfo,
  packagesFilter,
  packages,
  faqData,
  Testimonials1,
  wellnessTreatmentCarousel,
  blogFilter,
  customerReviews,
  specialitiesObj,
  specialitiesInfoObj,
};
